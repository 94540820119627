<template>
  <Dialog
    :modal="true">
    <template #header>
      <h3>Case review</h3>
    </template>
    <div class="grid">
      <div class="col-12 md:col-4">
        <div class="card widget-surgery-details">
          <div>
            <span class="overview-title">FACILITY</span>
            <div class="overview-text">{{ facilityName }}</div>
          </div>
          <div>
            <span class="overview-title">SESSION NUMBER</span>
            <div class="overview-text">{{ sessionNumber }}</div>
          </div>
          <div>
            <span class="overview-title">SURGERY TYPE</span>
            <div class="overview-text">{{ surgeryType }}</div>
          </div>
          <div>
            <span class="overview-title">START TIME</span>
            <div class="overview-text">{{ startTime }}</div>
          </div>
          <div>
            <span class="overview-title">AGE</span>
            <div class="overview-text">{{ age }}</div>
          </div>
          <div>
            <span class="overview-title">HEIGHT (CM)</span>
            <div class="overview-text">{{ height }}</div>
          </div>
          <div>
            <span class="overview-title">WEIGHT (KG)</span>
            <div class="overview-text">{{ weight }}</div>
          </div>
          <div>
            <span class="overview-title">SLU SCORE</span>
            <div class="overview-text">{{ sluScore }}</div>
          </div>
        </div>
        <div class="card">
          <h5>Hypotensive Episodes</h5>
          <DataTable
              :value="iohData"
              dataKey="id"
              class="p-datatable-gridlines">
            <Column field="Desc" header="MAP">
              <template #body="{data}">
                {{data.Desc}}
              </template>
            </Column>
            <Column field="Duration" header="Duration (mins)">
              <template #body="{data}">
                {{ data.Duration }}
              </template>
            </Column>
            <Column field="Episodes" header="Episodes">
              <template #body="{data}">
                {{data.Episodes}}
              </template>
            </Column>
          </DataTable>
          <h5>Target control accuracy</h5>
          <TargetTable
              :secondsUnderTarget="secondsUnderTarget"
              :secondsInTarget="secondsInTarget"
              :secondsAboveTarget="secondsAboveTarget"></TargetTable>
        </div>
      </div>
      <div class="col-12 md:col-8">
        <div class="card">
          <div class="grid grid-nogutter">
            <h5>Mean arterial pressure</h5>
            <i class="pi pi-info-circle ml-1" v-tooltip="'Hold shift and click-and-drag to zoom plot'"></i>
          </div>
          <div v-if="showMapChartLoadingCircle" class="flex justify-content-center align-items-center" style="height: 50vh">
            <ProgressSpinner />
          </div>
          <div v-else>
            <Button label="Reset zoom" class="p-button-outlined mr-2 mb-2" @click="clickZoomButton" v-tooltip="'Reset both charts zoom to their original level'"/>
            <IndexChart ref="mapChart" type="line" :data="lineData" :options="lineOptionsCalc" style="height: 50vh"></IndexChart>
          </div>
        </div>
        <div class="card">
          <div class="grid grid-nogutter">
            <h5>Advanced parameters</h5>
            <i class="pi pi-info-circle ml-1" v-tooltip="'Hold shift and click-and-drag to zoom plot'"></i>
          </div>
          <div v-if="showAdvancedParamsChartLoadingCircle" class="flex justify-content-center align-items-center" style="height: 50vh">
            <ProgressSpinner />
          </div>
          <div v-else>
            <Button label="Reset zoom" class="p-button-outlined mr-2 mb-2" @click="clickZoomButton" v-tooltip="'Reset both charts zoom to their original level'"/>
            <Button label="Baseline" :class="baselineButtonClass" @click="clickBaselineButton" v-tooltip="'Toggle to enable baseline adjustment, click anywhere on chart to set baseline'"/>
            <IndexChart ref="advParamChart" type="line" :data="advParamData" :options="advParamOpts" @select="selectNearestPoint" ></IndexChart>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
    </template>
  </Dialog>
</template>

<script>
import {format, parseISO} from "date-fns";
import 'chartjs-adapter-date-fns';
import TargetTable from "./TargetTable";
import IndexChart from "./IndexChart";
export default {
  components: {
    TargetTable,
    IndexChart
  },
  data() {
    return {
      plotInterationMode: "zoom",
      markersVisible: true,
      baselineIndex: 0,
    }
  },
  props: {
    case: {
      default() {
        return null
      },
      type: Object
    },
    timeCO:{
      default() {
        return []
      } ,
      type: Array
    },
    timeMAP:{
      default() {
        return []
      },
      type: Array
    },
    timeSVR:{
      default() {
        return []
      },
      type: Array
    },
    timeHR:{
      default() {
        return []
      },
      type: Array
    },
    timeTargets:{
      default() {
        return []
      },
      type: Array
    },
    eventsData:{
      default() {
        return []
      },
      type: Array
    },
    timeSLU:{
      default() {
        return []
      },
      type: Array
    },
  },
  methods: {
    clickZoomButton() {
      this.$refs["mapChart"].resetZoom()
      this.$refs["advParamChart"].resetZoom()
    },
    clickBaselineButton() {
      if (this.plotInterationMode == "zoom")
        this.plotInterationMode = "baseline"
      else
        this.plotInterationMode = "zoom"
    },
    selectNearestPoint(event) {
      if (this.plotInterationMode == "baseline")
        this.baselineIndex = event["element"]["index"]
    },
    onZoomComplete(event) {
      this.$refs.advParamChart.setXAxisZoom({ min: event["chart"]["scales"]["x"]["min"], max: event["chart"]["scales"]["x"]["max"]})
      this.$refs.mapChart.setXAxisZoom({ min: event["chart"]["scales"]["x"]["min"], max: event["chart"]["scales"]["x"]["max"]})
    },
    onPan(event) {
      this.$refs.advParamChart.setXAxisZoom({ min: event["chart"]["scales"]["x"]["min"], max: event["chart"]["scales"]["x"]["max"]})
      this.$refs.mapChart.setXAxisZoom({ min: event["chart"]["scales"]["x"]["min"], max: event["chart"]["scales"]["x"]["max"]})
    },
    onClickMapLegend(event, legendItem, legend) {
      const index = legendItem.datasetIndex;
      const ci = legend.chart;
      if (ci.isDatasetVisible(index)) {
        ci.hide(index);
        legendItem.hidden = true;
      } else {
        ci.show(index);
        legendItem.hidden = false;
      }
      if (legendItem.text == "Markers") {
        this.markersVisible = ! this.markersVisible
      }
    },
  },
  computed: {
    showMapChartLoadingCircle() {
      return !this.timeMAP
    },
    showAdvancedParamsChartLoadingCircle() {
      return !this.timeCO
    },
    facilityName() {
      if (!this.case)
        return "-"
      return this.case["SGS_FacilityName"]
    },
    sessionNumber() {
      if (!this.case)
        return "-"
      return this.case["SGS_GuId"]
    },
    surgeryType() {
      if (!this.case || !this.case["SPI_SurgeryType"])
        return "-"
      return this.case["SPI_SurgeryType"]
    },
    startTime() {
      if (!this.case)
        return "-"
      return format(this.case["SGS_StartTime"],'iii d MMM yyyy HH:mm')
    },
    height() {
      if (!this.case)
        return ""
      const height = this.case["SPI_Height"]
      if (!height)
        return "-"
      return height
    },
    weight() {
      if (!this.case)
        return ""
      const weight = this.case["SPI_Weight"]
      if (!weight)
        return "-"
      return weight
    },
    age() {
      if (!this.case)
        return ""
      const min = this.case["SPI_AgeMin"]
      const max = this.case["SPI_AgeMax"]
      if (!min && !max)
        return "-"
      return min + " - " + max
    },
    sluScore() {
      if (!this.case)
        return ""
      return this.case["STS_Normo_SLU"]
    },
    iohData() {
      return ([
        {
          "id": 0,
          "Desc": "<75",
          "Duration": Math.round(this.case["STS_IOH_Under75"] / 60),
          "Episodes": this.case["STS_IOH_EpisodesCount_Under75"],
        },
        {
          "id": 1,
          "Desc": "<65",
          "Duration": Math.round(this.case["STS_IOH_Under65"] / 60),
          "Episodes": this.case["STS_IOH_EpisodesCount_Under65"],
        },
        {
          "id": 2,
          "Desc": "<55",
          "Duration": Math.round(this.case["STS_IOH_Under55"] / 60),
          "Episodes": this.case["STS_IOH_EpisodesCount_Under55"],
        },
      ])
    },
    secondsUnderTarget() {
      return this.case["STS_MapDuration_UnderTarget"]
    },
    secondsInTarget() {
      return this.case["STS_MapDuration_InTarget"]
    },
    secondsAboveTarget() {
      return this.case["STS_MapDuration_AboveTarget"]
    },
    lineData() {
      let mapData = []
      let sluData = []
      if (this.timeMAP)
        mapData = this.timeMAP.map(point => {
          return {
            x: parseISO(point["x"]),
            y: point["y"]
          }
        })
      if (this.timeSLU)
        sluData = this.timeSLU.map(point => {
          return {
            x: parseISO(point["x"]),
            y: point["y"]
          }
        })
      return {
        datasets: [{
          label: "MAP (Mean Arterial Pressure)",
          data: mapData,
          borderColor: '#00413B',
          backgroundColor: '#00413B',
          borderWidth: 2,
          pointRadius: 0,
          showLine: true,
        },
          {
            label: "Markers",
            data: [],
            borderColor: '#00413B',
            backgroundColor: '#00413B',
            borderWidth: 2,
            pointRadius: 0,
            showLine: true,
          },
        {
          label: "SLU Score",
          data: sluData,
          borderColor: '#d00a0a',
          backgroundColor: '#d00a0a',
          borderWidth: 2,
          pointRadius: 0,
          showLine: true,
          stepped: true,
          hidden: true
        },
        ]
      }
    },
    advParamOpts() {
      return {
        animations: false,
        plugins: {
          zoom: {
            pan: {
              enabled: true,
              onPan: this.onPan
            },
            zoom: {
              wheel: {
                enabled: false,
              },
              pinch: {
                enabled: false
              },
              drag: {
                enabled: true,
                modifierKey: "shift"
              },
              mode: 'xy',
              onZoomComplete: this.onZoomComplete,
            },
          },
        },
        responsive: true,
        scales: {
          y: {
            title: {
              display: true,
              text: '% change from baseline'
            },
            min: 0,
            max: 200,
            grid: {
              color: 'rgba(160, 167, 181, .3)',
            }
          },
          x: {
            type: 'time',
            min: this.case["SGS_StartTime"],
            max: this.case["SGS_EndTime"]
          }
        },
      }
    },
    advParamData() {
      const baselineIndex = this.baselineIndex
      let relativeCo = []
      let relativeHr = []
      let relativeSvr = []
      if (this.timeCO)
        relativeCo = this.timeCO.map((point) => {
          if (point["y"] == null)
            return point
          return {
            x: parseISO(point["x"]),
            y: (point["y"] - this.timeCO[baselineIndex]["y"])
                / this.timeCO[baselineIndex]["y"] * 100 + 100
          }
        })
      if (this.timeHR)
        relativeHr = this.timeHR.map((point) => {
          if (point["y"] == null)
            return point
          return {
            x: parseISO(point["x"]),
            y: (point["y"] - this.timeHR[baselineIndex]["y"])
                / this.timeHR[baselineIndex]["y"] * 100 + 100
          }
        })
      if (this.timeSVR)
        relativeSvr = this.timeSVR.map((point) => {
          if (point["y"] == null)
            return point
          return {
            x: parseISO(point["x"]),
            y: (point["y"] - this.timeSVR[baselineIndex]["y"])
                / this.timeSVR[baselineIndex]["y"] * 100 + 100
          }
        })
      return {
        datasets: [{
          label: "CO (Cardiac Output)",
          data: relativeCo,
          borderColor: '#1A77D2',
          backgroundColor: '#1A77D2',
          borderWidth: 2,
          pointRadius: 0,
          showLine: true,
        },
          {
            label: "HR (Heart Rate)",
            data: relativeHr,
            borderColor: '#FBBC36',
            backgroundColor: '#FBBC36',
            borderWidth: 2,
            pointRadius: 0,
            showLine: true,
          },
          {
            label: "SVR (Systemic Vascular Resistance)",
            data: relativeSvr,
            borderColor: '#D345DA',
            backgroundColor: '#D345DA',
            borderWidth: 2,
            pointRadius: 0,
            showLine: true,
          }]
      }
    },
    lineOptionsCalc() {
      return {
        animations: false,
        plugins: {
          zoom: {
            pan: {
              enabled: true,
              onPan: this.onPan
            },
            zoom: {
              wheel: {
                enabled: false,
              },
              drag: {
                enabled: true,
                modifierKey: "shift",
              },
              pinch: {
                enabled: false
              },
              mode: 'xy',
              onZoomComplete: this.onZoomComplete,
            }
          },
          annotation: {
            annotations: this.mapChartAnnotations
          },
          legend: {
            display: true,
            onClick: this.onClickMapLegend
          }
        },
        responsive: true,
        scales: {
          y: {
            title: {
              display: true,
              text: 'mmHg'
            },
            min: 0,
            max: 130,
            grid: {
              color:  'rgba(160, 167, 181, .3)',
            }
          },
          x: {
            type: 'time',
            min: this.case["SGS_StartTime"],
            max: this.case["SGS_EndTime"],
          }
        }
      };
    },
    baselineButtonClass() {
      return this.plotInterationMode == "baseline" ?
          "p-button-filled mr-2 mb-2" :
          "p-button-outlined mr-2 mb-2"
    },
    mapChartAnnotations() {
      let eventOptions = []
      let eventsAnnotations = {}
      if (this.markersVisible)
      {
        eventOptions = this.eventsData.map(events=>{
          return {
            // id : events.Id,
            created: parseISO(events.Created),
            content: [events.TherapyCategory, events.DrugFormalName]
          }
        });
        for (let i = 0; i < eventOptions.length; i ++) {
          let curr = eventOptions[i]
          eventsAnnotations['ln_'+i] = {
            type: 'line',
            xMin: curr.created,
            xMax: curr.created,
            borderColor: 'rgb(0, 255, 0)',
            borderWidth: 1,
          },
              eventsAnnotations['lbl_'+i] = {
                type : 'label',
                rotation:270,
                xValue: curr.created,
                yValue:100,
                backgroundColor:'rgba(245,245,245)',
                content: curr.content,
                font : {
                  size: 8
                }
              }
        }
      }
      if (this.timeTargets == null || this.timeTargets.length == 0)
        return eventsAnnotations;
      const targetTrends = this.timeTargets.map(point => {
        return {
          x: parseISO(point["x"]),
          mapMin: point["mapMin"],
          mapMax: point["mapMax"]
        }
      })
      const lastTargetRange = targetTrends[targetTrends.length - 1]
      let targetsForAnnotations = [...targetTrends];
      targetsForAnnotations.push({
        "x": this.case["SGS_EndTime"],
        "mapMin": lastTargetRange["mapMin"],
        "mapMax": lastTargetRange["mapMax"],
      })
      for (let i = 0; i < targetsForAnnotations.length - 1; i++) {
        eventsAnnotations[`targetAnnotation_${i}`] = {
          type: 'box',
          xMin: targetsForAnnotations[i]["x"],
          xMax: targetsForAnnotations[i+1]["x"],
          yMin: targetsForAnnotations[i]["mapMin"],
          yMax: targetsForAnnotations[i]["mapMax"],
          backgroundColor: "#C5EECC",
          drawTime: "beforeDraw",
          borderWidth: 0
        }
      }
      return eventsAnnotations;
    },
  },
  watch: {
    timeCO() {
      if (!this.timeCO)
        return 0
      this.baselineIndex = this.timeCO.findIndex(el => el["y"] != null);
    }
  }
}
</script>